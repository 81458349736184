import React, {useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'

import {URL} from '../../../constants'

import Text from '../../Text'
import ExternalLinkPopUp from '../../ExternalLinkPopUp'
import MSDLogo from '../../../assets/images/svgs/msd-logo.inline.svg'

import {
  footer,
  footerHeadings,
  footerWithBorder,
  footerBox,
  footerBoxHeading,
  logoContainer,
  footerCookieLink,
  footerLinkButton,
} from './footer.module.scss'

interface Props {
  jobCode: string
  dateOfPrep: string
}

const Footer: React.FC<Props> = ({jobCode, dateOfPrep}) => {
  const [showExternalLinkPopUp, setShowExternalLinkPopUp] = useState(false)

  return (
    <footer className={footer}>
      <Container className="ps-lg-5 pt-5 pb-0">
        <Row className={`${footerHeadings} mb-4 px-lg-5`}>
          <Col
            lg={3}
            sm={4}
            xs={4}
            className={`${footerWithBorder} text-start`}
          >
            <a href={URL.TERMS_OF_USE} target="_blank" rel="noreferrer">
              {'Terms of Use'}
            </a>
          </Col>

          <Col
            lg={5}
            sm={4}
            xs={4}
            className={`${footerWithBorder} text-lg-center text-start`}
          >
            <a href={URL.PRIVACY_POLICY} target="_blank" rel="noreferrer">
              {'Privacy Policy'}
            </a>
          </Col>

          <Col lg={4} sm={4} xs={4} className={`text-lg-center text-start`}>
            <button
              className={`text-start ot-sdk-show-settings ${footerCookieLink}`}
            >
              {'Cookie Preferences'}
            </button>
          </Col>
        </Row>
        <Row className={`${footerBox} mx-lg-5 mt-4`}>
          <Col className="p-4">
            <p className={footerBoxHeading}>Reporting of side effects</p>
            <Text>
              If you get any side effects with any medicines, talk to your
              doctor, pharmacist or nurse. This includes any possible side
              effects not listed in the medicines package leaflet. You can also
              report side effects directly via the Yellow Card Scheme at
              <button
                className={footerLinkButton}
                onClick={() => setShowExternalLinkPopUp(true)}
              >
                {URL.YELLOW_CARD_SCHEME}
              </button>{' '}
              or by searching for MHRA Yellow Card in the Google Play or Apple
              App Store.
            </Text>
            <Text>
              By reporting side effects you can help provide more information on
              the safety of medicines. By clicking the above link you will leave
              the MSD website and be taken to the MHRA website.
            </Text>
            <ExternalLinkPopUp
              show={showExternalLinkPopUp}
              link={URL.YELLOW_CARD_SCHEME}
              handleClose={() => setShowExternalLinkPopUp(false)}
            />
          </Col>
        </Row>
        <Row className="pt-4 pb-5 mx-lg-5">
          <Col lg={3} md={3} sm={4} xs={4} className={logoContainer}>
            <MSDLogo />
          </Col>
          <Col lg={5} md={12}>
            <Text customClassName="date-of-prep">{dateOfPrep}</Text>
            <Text customClassName="job-bag-no">{jobCode}</Text>
            <Text>Merck Sharp & Dohme (UK) Limited</Text>
            <Text>
              Registered Office: 120 Moorgate, London, EC2M 6UR, United Kingdom
            </Text>
          </Col>
          <Col lg={4} md={12}>
            <Text>Registered in England No. 233687</Text>
            <Text>
              Copyright © 2023 Merck & Co., Inc., Rahway, NJ, USA and its
              affiliates. All rights reserved.
            </Text>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
