import React from 'react'
import {Link} from 'gatsby'
import {Navbar, Container, Nav} from 'react-bootstrap'
import {URL} from '../../../constants'
import '../Header/Header.scss'
import {header, headerNavItem} from './HeaderTeenage.module.scss'
import Logo from '../Header/logo.inline.svg'

const HeaderTeenage = () => {
  return (
    <>
      <Navbar as="header" sticky="top" expand="lg" className={header}>
        <Container
          fluid="xxl"
          as="nav"
          className="py-1 py-sm-2 px-sm-4 gap-2 gap-lg-0"
        >
          <Navbar.Brand className="py-2">
            <Link to={URL.HOME}>
              <Logo className="inline-block align-top logo-teenager" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-nav" className="pt-1 my-2" />
          <Navbar.Collapse id="main-nav" className="justify-content-end">
            <Nav className="justify-content-around gap-1 gap-lg-5">
              <Link className={`${headerNavItem} nav-link`} to={URL.HOME}>
                Information for parents
              </Link>
              <Link className={`${headerNavItem} nav-link`} to={URL.FAQS}>
                FAQs
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default HeaderTeenage
