import {useState, useEffect} from 'react'
import {getFormProperties} from '../feedback-utils'
import {getCookie} from '../../../utils'

export function useShowFeedback(formName?: string) {
  const [showFeedback, setShowFeedback] = useState(false)
  const {surveyStatusCookieName} = getFormProperties(formName)
  const shouldShowSurveyForm = () => getCookie(surveyStatusCookieName) == null

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const htmlEl = document.getElementsByTagName('html')[0]

    function scrollFunction() {
      const element = document.body.parentElement

      if (element == null) {
        document.removeEventListener('scroll', scrollFunction)
        return
      }

      if (!shouldShowSurveyForm()) return

      const boundingRect = element.getBoundingClientRect()
      const offsetTop = Math.abs(boundingRect.top)
      const totalHeight = Math.abs(boundingRect.height)

      const deviceHeight = window.screen.height

      const halfDeviceHeight = deviceHeight / 2

      const scrollPercentage = (offsetTop + halfDeviceHeight) / totalHeight

      const minimumScrollPercentage = halfDeviceHeight / totalHeight

      let actualScrollPercentage =
        (scrollPercentage - minimumScrollPercentage) /
        (1 - 2 * minimumScrollPercentage)

      if (actualScrollPercentage < 0) {
        actualScrollPercentage = 0
      }
      if (actualScrollPercentage > 1) {
        actualScrollPercentage = 1
      }

      const scrollTrigger = isMobileDevice() ? 0.6 : 0.75
      if (actualScrollPercentage > scrollTrigger) {
        setShowFeedback(true)
      }
    }

    // On scroll trigger
    document.addEventListener('scroll', scrollFunction)
    return () => {
      document.removeEventListener('scroll', scrollFunction)
    }
  }, [surveyStatusCookieName])

  return [showFeedback, setShowFeedback] as const
}

function isMobileDevice() {
  const agent = navigator.userAgent
  const isWebkit = agent.indexOf('AppleWebKit') > 0
  const isIPad = agent.indexOf('iPad') > 0
  const isIOS = agent.indexOf('iPhone') > 0 || agent.indexOf('iPod') > 0
  const isAndroid = agent.indexOf('Android') > 0
  const isNewBlackBerry =
    agent.indexOf('AppleWebKit') > 0 && agent.indexOf('BlackBerry') > 0
  const isWebOS = agent.indexOf('webOS') > 0
  const isWindowsMobile = agent.indexOf('IEMobile') > 0
  const isSmallScreen =
    window.screen.width < 767 || (isAndroid && window.screen.width < 1000)
  const isUnknownMobile = isWebkit && isSmallScreen
  const isMobile =
    isIOS ||
    isAndroid ||
    isNewBlackBerry ||
    isWebOS ||
    isWindowsMobile ||
    isUnknownMobile
  const isTablet = isIPad || (isMobile && !isSmallScreen)
  return isMobile || isTablet
}
