import React from 'react'
import {text} from './text.module.scss'

interface Props {
  children: React.ReactNode
  color?: string
  customClassName?: string
}

const Text: React.FC<Props> = ({children, color = '#fff', customClassName}) => {
  const style = {
    color,
  }
  return (
    <p className={`${text} ${customClassName}`} style={style}>
      {children}
    </p>
  )
}

export default Text
