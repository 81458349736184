import React from 'react'
import {GatsbyBrowser} from 'gatsby'
import './src/assets/styles/main.scss'
import {getPageName} from './src/utils/pageUtil'
import {Layout} from './src/components/Layout'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  const cookieBtn = document.querySelector('.ot-sdk-show-settings')
  const oneTrust = window.OneTrust

  if (cookieBtn && oneTrust) {
    cookieBtn.addEventListener('click', (event) => {
      event.preventDefault()
      oneTrust.ToggleInfoDisplay()
    })
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const pageName = getPageName(props.uri)
  return <Layout pageName={pageName}>{element}</Layout>
}
