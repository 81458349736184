import React from 'react'
import {Navbar, Container, Nav} from 'react-bootstrap'
import {URL} from '../../../constants'
import {Link} from 'gatsby'

import Logo from './logo.inline.svg'

import './Header.scss'

export interface NavLink {
  text: string
  url: string
}

interface Props {
  navLinks: NavLink[]
}

const Header = ({navLinks}: Props) => {
  return (
    <Navbar as="header" sticky="top" expand="lg" className="header">
      <Container
        fluid="xxl"
        as="nav"
        className="py-1 py-sm-2 px-sm-4 gap-2 gap-lg-0"
      >
        <Navbar.Brand className="py-2">
          <Link to={URL.HOME}>
            <Logo className="inline-block align-top" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-nav" className="pt-1 my-2" />
        <Navbar.Collapse id="main-nav" className="justify-content-end">
          <Nav className="justify-content-around gap-1 gap-lg-5">
            {navLinks.map(({text, url}) => (
              <Link key={text} className="navItem nav-link" to={url}>
                {text}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
