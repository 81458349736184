import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import FeedbackForm from './FeedbackForm'
import {getFormProperties, pushFormEventToGA} from './feedback-utils'

import {title, closeButton} from './Feedback.module.scss'
import {useShowFeedback} from './hooks/useShowFeedback'

interface Props {
  pageName?: string
}

const Feedback = ({pageName}: Props) => {
  const [showFeedback, setShowFeedback] = useShowFeedback(pageName)
  const [feedbackSumbitted, setFeedbackSumbitted] = useState(false)

  const {eventCategory, surveyStatusCookieName} = getFormProperties(pageName)

  const handleCloseFormButton = () => {
    pushFormEventToGA({
      cookieName: surveyStatusCookieName,
      cookieValue: 'survey-shown',
      eventLabel: 'survey closed',
      eventCategory,
    })

    resetForm()
  }

  const resetForm = () => {
    setFeedbackSumbitted(false)
    setShowFeedback(false)
  }

  return (
    <Modal show={showFeedback} onHide={resetForm} centered size="lg">
      <Modal.Body
        className="border border-4 rounded border-secondary border-tertiary p-2 pb-4 text-secondary text-tertiary text-center"
        style={{backgroundColor: '#f7f7f7'}}
      >
        <Button
          variant="secondary"
          className={closeButton}
          onClick={handleCloseFormButton}
        >
          <span />
        </Button>

        <StaticImage
          src="./popup-image.jpg"
          alt="Person holding big question mark."
          width={350}
        />

        {feedbackSumbitted ? (
          <>
            <p className="fs-5">
              Thank you for your feedback! Please speak to your school
              <br />
              vaccination team to find out more.
            </p>
            <p className="fs-5 mb-5">Click anywhere to close this window.</p>
            <Button
              variant="outline-tertiary"
              className="rounded-pill outline-tertiary"
              style={{borderWidth: '3px', padding: '0.75rem 4rem'}}
              onClick={resetForm}
            >
              Close window
            </Button>
          </>
        ) : (
          <>
            <p className="fs-5">
              After visiting HPVwise, do you feel informed enough
              <br /> to speak to your school vaccination team about...
            </p>
            <h2 className={title}>
              Taking part in the
              <br /> free NHS National Vaccination Programme?
            </h2>
            <FeedbackForm
              pageName={pageName}
              setFeedbackSumbitted={setFeedbackSumbitted}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default Feedback
