import React, {ReactNode} from 'react'
import Feedback from '../Feedback'

interface Props {
  children: ReactNode
  pageName: string
}

const Layout = ({children, pageName}: Props) => {
  return (
    <>
      {children}
      <Feedback pageName={pageName} />
    </>
  )
}

export default Layout
