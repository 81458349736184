// URL links for navigation
export const URL = {
  HOME: '/',
  TEENAGERS: '/teenagers',
  TEENAGERS_FAQ: '/teenagers/faq',
  WHY_JOIN: `/#WhyJoinTheFight`,
  HOW_JOIN: `/#HowToJoinTheFight`,
  FAQS: `/faqs`,
  TERMS_OF_USE: 'http://www.msdproduct.co.uk/terms_of_use/TermsofUse.pdf',
  PRIVACY_POLICY: 'https://www.msdprivacy.com/uk/en/index.html',
  YELLOW_CARD_SCHEME: 'https://yellowcard.mhra.gov.uk',
}

// Direct FAQ url redirect hashtags
export const FAQ = {
  CHILD_OLDER: `${URL.FAQS}#childOlder`,
  MISSED_DOSE: `${URL.FAQS}#childMissedDose`,
  WHY_BOYS: `${URL.FAQS}#boysAdded`,
}

// URL to the video presentation
export const VIDEO_URL = {
  PARENTS:
    'https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6322620368112',
  TEENAGERS:
    'https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6327760105112',
}
